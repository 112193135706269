import React from 'react';
import ReportItem from "./reprt-item";

const ReportTwoItem = (props) => {


    return (
        <div className="report-item-core">
            <div className="report-item-header">
                {props.name}
            </div>
            <div className={`report-item-container ${props.addClass || ''}`}>
                {
                    props.data.map((item, index) => (
                        <ReportItem key={`report-two-item-${index}`}
                                    name={item.name}
                                    items={item.data || []}
                                    type={item.type}
                                    stepInfo={props.stepInfo}
                                    closeInfo={props.closeInfo}
                                    type_lead={item.type_lead}
                                    openModal={props.openModal}
                                    template_type={props.template_type}
                                    number={item.number}/>
                    ))
                }

            </div>
        </div>

    )
}

export default ReportTwoItem;