import React, {Component} from 'react';
import './App.css';
import Page from './core/page'
import ReportItem from './elements/reprt-item'
import ReportTwoItem from './elements/report-two-items'
import Select from "react-select";
import Popper from "./elements/popper";
import Modal from "./elements/modal"

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            users: [],
            active_user: null,
            open_info: false,
            overdue: false,
            data: {},
            open_modal: false,
        };

    }

    async componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('key') !== 'bcbe26c8ae2144be5885fc53cdbea64e32a3e7a8') {
            return null;
        }

        const response = await fetch(`/server?key=bcbe26c8ae2144be5885fc53cdbea64e32a3e7a8`, {
            method: 'post'
        });
        const result = await response.json();

        if (result) {
            this.setState({
                data: result.data,
                users: result.users,
                isLoading: false,
                key: params.get('key') || null,
                type: params.get('type') || null,
                active_user: Number(params.get('user_id')) || null,
            });
        }


    }

    saveData = async () => {

        const response = await fetch(`/server/step?key=bcbe26c8ae2144be5885fc53cdbea64e32a3e7a8`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({next_step: this.state.modal_data, buffer: this.state.buffer})
        });
        const result = await response.json();

        if (result.id) {
            let clone = this.state.data;
            let index = this.state.data[this.state.buffer.type][this.state.buffer.number].findIndex(item => item.id === result.id);
            clone[this.state.buffer.type][this.state.buffer.number][index].step = [...[result.step], ...clone[this.state.buffer.type][this.state.buffer.number][index].step];
            this.setState({
                data: clone,
                open_modal: false
            });
        }

    }

    handleChange = (newValue, actionMeta) => {
        const value = newValue ? newValue.value : '';

        this.setState({
            [actionMeta.name]: value
        });


    };

    handleChangeText = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    };


    createOption = (data) => {
        let result = [];
        data.map(item => {
            result.push({value: item.id, label: item.name})
        })
        return result;
    }

    filterOverdue = () => {
        this.setState({
            overdue: !this.state.overdue,
        });
    }

    control = (users) => {
        let addClass = '';
        if (this.state.overdue) {
            addClass = 'overdue'
        }

        return (
            this.state.type !== 'user' ?
                <React.Fragment>
                    <Select
                        isClearable
                        isSearchable
                        cacheOptions
                        placeholder='Манагер'
                        name='active_user'
                        onChange={this.handleChange}
                        options={users}
                    />
                    <button className={`button-input ${addClass}`} onClick={this.filterOverdue}>
                        Просроченные лиды
                    </button>
                </React.Fragment> : null
        )
    }

    filterUser = (data, price) => {
        if (this.state.overdue && !price) {
            data = data.filter(item => Math.ceil((Math.floor(new Date() / 1000) - item.date) / 86400) > 7)
        }
        if (!this.state.active_user) {
            return data;
        }

        return data.filter(item => item.user === this.state.active_user)
    }

    stepInfo = (ref, step) => {
        if (step && step.length) {
            this.setState({
                open_info: true,
                step: step,
                ref: ref
            });
        }
    }

    closeInfo = (e) => {
        this.setState({
            open_info: false,
        });
    }

    handleModal = () => {
        this.setState({
            open_modal: !this.state.open_modal,
        });
    }

    openModal = (step, type, number, id) => {
        this.setState({
            open_modal: !this.state.open_modal,
            modal_data: step && step[0] || null,
            buffer: {type, number, id}
        });
    }

    closeModal = () => {
        if (this.state.open_modal) {
            document.getElementById('root').addEventListener("click", this.listener);
            document.body.style.overflow = 'hidden';
        } else {
            document.getElementById('root').removeEventListener("click", this.listener);
            document.body.style.overflow = '';
        }
    };

    listener = (e) => {
        const parentElement = document.getElementById('modal');
        let target = e.target;
        if (!parentElement.contains(target)) {
            this.handleModal()
        }
    };

    getTemplate = (data, users) => {
        switch (this.state.type) {
            case ('engineer'):
                return <Page addClass={this.state.type}>
                    <div className="report-item-core">
                        <div className="report-item-header">Не начато</div>
                        <div className="report-item-container">
                            <div className="report-item">
                                <ReportItem name={`Замер`} items={data.froze_one} stepInfo={this.stepInfo}
                                            closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`froze`}
                                            number={0} template_type={this.state.type}/>
                            </div>
                            <div className="report-item">
                                <ReportTwoItem name={`Гарантийка`} data={[
                                    {name: `Не начато`, data: data.warranty_one, type_lead: 'warranty', number: 0},
                                    {name: `В работе`, data: data.warranty_two, type_lead: 'warranty', number: 1}
                                ]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                               template_type={this.state.type}/>
                            </div>
                        </div>
                    </div>

                    <ReportTwoItem name={`В работе`} data={[
                        {name: `Замер`, data: data.froze_two, type_lead: 'froze', number: 1},
                        {name: `Проект`, data: data.project_two, type_lead: 'project', number: 1}
                    ]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                   template_type={this.state.type}/>

                    <ReportTwoItem name={`Не начато`} data={[
                        {name: `Монтаж`, data: data.installation_one, type_lead: 'installation', number: 0},
                        {name: `Проект`, data: data.project_one, type_lead: 'project', number: 0}
                    ]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                   template_type={this.state.type}/>
                    <ReportItem name={`Монтаж в работе`} items={data.installation_two} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`installation`}
                                number={1} template_type={this.state.type}/>

                </Page>
                break;
            case ('user'):
                return <Page addClass={this.state.type}>
                    <ReportTwoItem addClass={this.state.type} name={`Не начато`} data={[
                        {name: `Замер`, data: data.froze_one, type_lead: 'froze', number: 0},
                        {name: `Монтаж`, data: data.installation_one, type_lead: 'installation', number: 0},
                        {name: `Гарантийка`, data: data.warranty_one, type_lead: 'warranty', number: 0},
                        {name: `ТЗ на проект не переданно`, data: data.project_one, type_lead: 'project', number: 0},
                        {name: `ТЗ на проект переданно`, data: data.project_two, type_lead: 'project', number: 1}
                    ]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                   template_type={this.state.type}/>

                    <ReportTwoItem addClass={`engineer`} name={`В работе`} data={[
                        {name: `Замер`, data: data.froze_two, type_lead: 'froze', number: 1},
                        {name: `Гарантийка`, data: data.warranty_one, type_lead: 'warranty', number: 1},
                        {name: `Монтаж`, data: data.installation_two, type_lead: 'installation', number: 1}
                    ]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                   template_type={this.state.type}/>

                    <ReportTwoItem addClass={this.state.type} name={`Оплата/Доки`} data={[
                        {name: `Доки`, data: data.dock, type_lead: 'debit', number: 0},
                        {name: `Долги безнал`, data: data.debit_three, type_lead: 'debit', number: 3, type: 'price'},
                        {name: `Долги наличка`, data: data.debit_four, type_lead: 'debit', number: 2, type: 'price'},
                        {name: `Рассрочка безнал`, data: data.sale_two, type_lead: 'sale', number: 0, type: 'price'},
                        {name: `Рассрочка наличка`, data: data.sale_one, type_lead: 'sale', number: 1, type: 'price'},
                    ]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                   template_type={this.state.type}/>
                </Page>
                break;
            default:
                return <Page control={this.control(users)}>
                    <ReportItem name={`Замер не начат`} items={data.froze_one} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`froze`} number={0}
                                template_type={this.state.type}/>
                    <ReportItem name={`Монтаж не начат`} items={data.installation_one} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`installation`}
                                number={0} template_type={this.state.type}/>
                    <ReportItem name={`Гарантийка не начата`} items={data.warranty_one} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`warranty`}
                                number={0} template_type={this.state.type}/>
                    <ReportTwoItem name={`ТЗ на проект`} data={[{
                        name: `не переданно`,
                        data: data.project_one,
                        type_lead: 'project',
                        number: 0
                    }, {
                        name: `переданно`,
                        data: data.project_two, type_lead: 'project', number: 1
                    }]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}
                                   template_type={this.state.type}/>


                    <ReportItem name={`Доки`} items={data.dock} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`debit`} number={0}
                                template_type={this.state.type}/>

                    <ReportItem name={`Замер в работе`} items={data.froze_two} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`froze`} number={1}
                                template_type={this.state.type}/>
                    <ReportItem name={`Монтаж в работе`} items={data.installation_two} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`installation`}
                                number={1} template_type={this.state.type}/>
                    <ReportItem name={`Гарантийка в работе`} items={data.warranty_two} stepInfo={this.stepInfo}
                                closeInfo={this.closeInfo} openModal={this.openModal} type_lead={`warranty`}
                                number={1} template_type={this.state.type}/>

                    <ReportTwoItem name={`Рассрочка`}
                                   data={[{
                                       name: `Безнал`,
                                       data: data.sale_two,
                                       type_lead: 'sale',
                                       number: 0,
                                       type: 'price'
                                   }, {
                                       name: `Наличка`,
                                       data: data.sale_one, type_lead: 'sale', number: 1, type: 'price'
                                   }]} stepInfo={this.stepInfo} closeInfo={this.closeInfo}
                                   openModal={this.openModal} template_type={this.state.type}/>

                    <ReportTwoItem name={`Долги`}
                                   data={[{
                                       name: `Безнал`,
                                       data: data.debit_three,
                                       type_lead: 'debit',
                                       number: 3,
                                       type: 'price'
                                   }, {
                                       name: `Наличка`,
                                       data: data.debit_four, type_lead: 'debit', number: 2, type: 'price'
                                   }]} stepInfo={this.stepInfo} closeInfo={this.closeInfo}
                                   openModal={this.openModal} template_type={this.state.type}/>

                </Page>;


        }
    }

    render() {
        const users = this.createOption(this.state.users);
        this.closeModal()
        let data = {};
        data.froze_one = this.filterUser((this.state.data && this.state.data.froze && this.state.data.froze[0]) || []);
        data.installation_one = this.filterUser((this.state.data && this.state.data.installation && this.state.data.installation[0]) || []);
        data.warranty_one = this.filterUser((this.state.data && this.state.data.warranty && this.state.data.warranty[0]) || []);
        data.project_one = this.filterUser((this.state.data && this.state.data.project && this.state.data.project[0]) || []);
        data.dock = this.filterUser((this.state.data && this.state.data.debit && this.state.data.debit[0]) || []);
        data.sale_one = this.filterUser((this.state.data && this.state.data.sale && this.state.data.sale[1]) || [], true);

        data.froze_two = this.filterUser((this.state.data && this.state.data.froze && this.state.data.froze[1]) || []);
        data.installation_two = this.filterUser((this.state.data && this.state.data.installation && this.state.data.installation[1]) || []);
        data.warranty_two = this.filterUser((this.state.data && this.state.data.warranty && this.state.data.warranty[1]) || []);
        data.project_two = this.filterUser((this.state.data && this.state.data.project && this.state.data.project[1]) || []);
        data.debit_three = this.filterUser((this.state.data && this.state.data.debit && this.state.data.debit[3]) || [], true);
        data.debit_four = this.filterUser((this.state.data && this.state.data.debit && this.state.data.debit[2]) || [], true);
        data.sale_two = this.filterUser((this.state.data && this.state.data.sale && this.state.data.sale[0]) || [], true);

        return (
            <div className="App">
                {
                    this.state.isLoading ? '' :
                        this.getTemplate(data, users)
                }

                {
                    this.state.open_info ?
                        <Popper setRef={this.state.ref} placement="left">
                            {
                                this.state.step && this.state.step.length ?
                                    this.state.step.map((item, index) => (
                                        <div key={`step-${index}`}>
                                            {item}
                                        </div>
                                    )) : null
                            }
                        </Popper> : null
                }

                {this.state.open_modal ?
                    <div>
                        <Modal
                            handleModal={this.handleModal}
                        >
                            <div className="modal-body">
                                <textarea name="modal_data" cols="30" rows="5" value={this.state.modal_data}
                                          onChange={this.handleChangeText}>

                                </textarea>
                            </div>


                            <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={this.handleModal}>Закрыть</button>
                                <button type="submit" className="btn btn-primary" onClick={this.saveData}>Сохранить
                                </button>
                            </div>
                        </Modal>
                    </div> : null
                }
            </div>
        );
    }

}

/*<ReportTwoItem name={`Доки`} data={[{name: `Безнал`, data: debit_one, type_lead: 'debit', number: 1}, {
                                name: `Наличка`,
                                data: debit_two, type_lead: 'debit', number: 0
                            }]} stepInfo={this.stepInfo} closeInfo={this.closeInfo} openModal={this.openModal}/>*/

export default App;
