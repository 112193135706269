import React, { useState } from 'react';
import { usePopper } from 'react-popper';

const Popper = (props) => {


    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(props.setRef.current, popperElement);

    return (

            <div className='step-data' ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                {props.children}
            </div>

    );
};

export default Popper;