import React from 'react';
import ReportSubItem from "./report-sub-item";

const ReportItem = (props) => {


    return (
        <div className="report-item">
            <div className="report-item-header">{props.name}
                <div className="leads-count">{props.items.length}</div>
            </div>
            <div className="report-item-container">
                {
                    props.items.map(item => (
                        <ReportSubItem key={`report-item-${item.id}`}
                                       item={item} type={props.type}
                                       stepInfo={props.stepInfo}
                                       closeInfo={props.closeInfo}
                                       type_lead={props.type_lead}
                                       openModal={props.openModal}
                                       template_type={props.template_type}
                                       number={props.number}/>
                    ))
                }

            </div>
        </div>
    )
}

export default ReportItem;