import React, {useRef} from "react";

const ReportSubItem = ({item, type, stepInfo, closeInfo, type_lead, number, openModal, template_type}) => {

    const elementRef = useRef(null);

    const getDayLeft = (time) => {
        let date = Math.floor(new Date()/1000);
        let day = Math.ceil((date-time)/86400);
        let classAdd = '';
        if (day > 7){
            classAdd = 'day-more';
        }
        if (day < 0){
            classAdd = 'day-less';
        }
        return <div className={`day-left ${classAdd}`}>{day}дн</div>;
    }

    const getPrice = (price) => {
        return <div className={`day-left`}>{price}</div>;
    }


    const step = (data, id) => {
        let classAdd = '';
        if (!data || !data.length){
            classAdd = 'no-step'
        }
        return (<div className={`next-step next-step-icon ${classAdd}`} onMouseOver={e => stepInfo(elementRef, data)} onMouseOut={closeInfo} onClick={(e) => openModal(data, type_lead, number, id)}>
           </div>);
    }

    let subclass = (type) => {
        let dockClass = '';
        if (type_lead === 'debit' && type === 1){
            dockClass = ' dock'
        }

        return 'report-sub-item'+dockClass;
    }



    return(
           <div className={subclass(item.type || null)} ref={elementRef}>
               <a href={`https://7v7.amocrm.ru/leads/detail/${item.id}`} target='_blank'>{item.name}</a>
               <div className="sub-item-info">
                   {
                       template_type && template_type === 'engineer' ? '' :
                           <div className="lead-id">{item.id}</div>
                   }
                   {type ? getPrice(item.debit) : getDayLeft(item.date)}
                   {step(item.step || null, item.id)}
               </div>
           </div>

    )
}

export default ReportSubItem;