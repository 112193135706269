import React from 'react';

const Page = (props) => {
    return(
        <div className={`cell-wrapper ${props.addClass || ''}`}>

            <div className="cell-wrapper__caption">
                <div className='cell-wrapper__name'>Отчет</div>
                <div className="cell-wrapper__control">
                    {props.control}
                </div>
            </div>
            <div className="cell-wrapper__data">
                {props.children}
            </div>
        </div>
    )
}

export default Page;